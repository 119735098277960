@use "../variables" as *;
@use "../components" as *;
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Montserrat:wght@300&family=Open+Sans:ital,wght@1,300&family=Poppins:wght@300&display=swap");

.reservation {
  width: 100%;
  height: 90vh;
  .card {
    width: 90%;
    height: 100%;
    background-image: url("../../images/reservation-img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .box {
      width: 40%;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border: 4px solid $black;
      padding: 3em;
      align-items: center;
      background-color: $white;
      h3 {
        font-family: "Libre Baskerville", serif;
        font-family: "Montserrat", sans-serif;
        font-family: "Open Sans", sans-serif;
        font-family: "Poppins", sans-serif;
      }
      .button {
        background-color: $main;
        margin-top: 2em;
        padding: 1em 3em 1em 3em;
        a {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
  @include respond-to("tablet") {
    .card {
      width: 90%;
      height: 100%;
      .box {
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 3em;
        h3 {
          font-size: 0.2em;
        }
        .button {
          margin-top: 1em;
          padding: 0.5em 2em 0.5em 2em;
        }
      }
    }
  }
  @include respond-to("mobile") {
    .card {
      width: 90%;
      height: 100%;
      .box {
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 3em;
        h3 {
          font-size: 0.1em;
        }
        .button {
          margin-top: 1em;
          padding: 0.5em 2em 0.5em 2em;
        }
      }
    }
  }
  @include respond-to("laptop") {
    .card {
      .box {
        height: 50%;
        width: 50%;
        h3 {
          font-size: 1em;
        }
      }
    }
  }
}
