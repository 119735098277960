@use "./variable" as *;

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: rgba($color: $main, $alpha: 0.2);
}
::-webkit-scrollbar-thumb {
  background-color: $main;
}
