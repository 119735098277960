@use "../container" as *;
@use "../variables" as *;
@use "../components" as *;

.about {
  .container {
    border: 1px solid $black;
    border-radius: 2em;
    margin-top: 8em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .background {
      padding: 0.5em;
      width: 55%;
      height: 100%;
      img {
        width: 90%;
        height: 90%;
        border-radius: 20px 0 0 20px;
        box-shadow: 0px 4px 11px 7px rgba(0, 0, 0, 0.25);
      }
    }
    .infor {
      width: 45%;
      height: 100%;
      .features {
        margin-top: 2em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        img {
          width: 4.5em;
          height: 4.5em;
          margin-right: 1.5em;
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          display: flex;
          p {
            width: 80%;
          }
        }
      }
    }
  }

  @include respond-to("tablet") {
    .container {
      margin-top: 1em;
      flex-direction: column;
      height: auto;
      .background {
        padding: 0.5em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90%;
          margin: 0;
          border-radius: 1em;
        }
      }
      .infor {
        margin-top: 1em;
        width: 85%;
      }
    }
  }
}
