@use "../container" as *;
@use "../variables" as *;
@use "../components" as *;

.menu {
  .container {
    height: 100%;
    align-items: center;
    text-align: center;
    margin: 5em 0 5em 0;
    display: flex;
    flex-direction: column;
    .menu-header {
      width: 100%;
      h1 {
        width: 100%;
        font-size: 2em;
      }
    }
    .vector {
      position: absolute;
    }
    .vector1 {
      top: 0;
      left: 0;
    }
    .vector2 {
      bottom: 0;
      right: 0;
    }
    .menu-text {
      display: flex;
      justify-content: center;
      p {
        width: 60%;
        font-size: 1.5em;
        font-family: $font-family;
        .narrow-img {
          height: 25px;
          width: 120px;
          margin-left: 1%;
          margin-bottom: -10px;
        }
        button {
          right: 5%;
          font-family: $font-family;
          font-size: 1em;
        }
      }
    }
    .menu-images {
      justify-content: center;
      height: auto;
      display: flex;
      padding-top: 5em;
      img {
        border-radius: 0.5em;
        margin-right: 2em;
        height: 400px;
        width: 250px;
        transition: transform 0.3s ease-out;
        box-shadow: 5px 5px 15px -3px $black;
      }
      .model-left {
        animation: move-up 4.5s ease-in-out infinite alternate-reverse;
        @keyframes move-up {
          to {
            transform: translateY(2em);
          }
        }
      }
      .model-right {
        animation: move-down 3s ease infinite alternate-reverse;
        @keyframes move-down {
          to {
            transform: translateY(-2em);
          }
        }
      }
    }
  }
  @include respond-to("tablet") {
    .container {
      height: auto;
      width: 95%;
      .vector {
        width: 8em;
        height: 8em;
      }
      .vector2 {
        display: none;
      }
      .menu-images {
        height: auto;
        margin-top: 1em;
        padding: 0;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        gap: 1em 2em;
        .modal-right {
          grid-area: 1 / 1 / 2 / 2;
        }
        .modal-right2 {
          grid-area: 1 / 2 / 2 / 3;
        }
        .modal-left {
          grid-area: 2 / 1 / 3 / 2;
        }
        .modal-left2 {
          grid-area: 2 / 2 / 3 / 3;
        }
        img {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
