@use "../components/mixin" as *;
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Open+Sans:ital,wght@1,300&family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Montserrat:wght@300&family=Open+Sans:ital,wght@1,300&family=Poppins:wght@300&family=Roboto&display=swap");

//color
$main: #66baba;
$secondary: #fffdf1;
$black: #04030f;
$white: #fffbff;

//font
$font-family: "Playball";
$fontBody: "Roboto Serif", serif;

@for $index from 1 through 6 {
  h#{$index} {
    font-family: $font-family;
    margin: 0.2em;
  }
}

input,
select,
textarea {
  font-family: "Libre Baskerville", serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  color: $white;
}

body,
html {
  font-family: "Libre Baskerville", serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  color: $black;
  line-height: 1.6;
  scroll-behavior: auto;
  margin: 0 !important;
  padding: 0 !important;
}
p {
  color: $black;
  font-size: 1em;
}
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Ensures it stays in place */
  z-index: 1000; /* High z-index to overlay other components */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.popup img {
  width: 30%;
  height: 80%;
  @include respond-to("laptop") {
    width: 60%;
    height: 70%;
  }
  @include respond-to("tablet") {
    width: 70%;
    height: 75%;
  }
  @include respond-to("mobile") {
    width: 80%;
    height: 70%;
  }
}
.close-icon {
  background-color: white;
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  padding: 0.2em;
  cursor: pointer;
}
a,
Link:hover {
  cursor: pointer;
}
:export {
  main: $main;
  secondary: $secondary;
  black: $black;
  white: $white;
  font-family: $font-family;
}
