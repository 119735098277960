@use "../variables" as *;
@use "../container" as *;
@use "./mixin" as *;

.footer {
  flex-direction: column;
  background-color: $black;
  .bottom {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7em;
    .line {
      margin-top: 3em;
      width: 80%;
      background-color: $white;
      height: 0.5px;
    }
    background-color: $black;
    color: $white;
  }
  .footer-container {
    width: 100%;
    height: 40vh;
    display: flex;
    .button {
      background-color: $white;
      color: $black;
      font-family: $fontBody;
      &:hover {
        color: $black;
        background-color: $white;
        text-decoration: underline 2px $main;
        text-underline-offset: 0.5em;
        transition: background-color 200ms ease text-decoration 200ms linear
          border-color 200ms ease;
      }
    }
    .left-footer {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .left-text {
        color: $white;
        margin-left: 10em;
        width: 70%;
        p {
          margin: 0.2em;
          color: gray;
        }
      }
    }
    .right-footer {
      width: 50%;
      color: $white;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .open-time {
        p {
          color: $main;
        }
        .button {
          background-color: $main;
          a {
            text-decoration: none;
            color: $white;
          }
        }
      }
      .social {
        .icon {
          color: $white;
        }
      }
    }
  }
  @include respond-to("laptop") {
    .footer-container {
      height: auto;
      padding: 2em;
      .left-footer {
        .left-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 5em;
        }
      }
      .right-footer {
        width: 50%;
        .heading {
          h2 {
            color: $white;
          }
          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
  @include respond-to("tablet") {
    width: 100%;
    .bottom {
      h4 {
        font-size: 0.9em;
        width: 80%;
        text-align: center;
      }
    }
    .footer-container {
      height: auto;
      padding: 2em;
      flex-direction: column;
      justify-content: space-evenly;
      .left-footer {
        width: 100%;
        .left-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 5em;
        }
      }
      .right-footer {
        margin-top: 3em;
        flex-direction: column;
        width: 100%;
        .social {
          margin-top: 2em;
        }
      }
    }
  }
}
