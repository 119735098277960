@use "../container" as *;
@use "../variables" as *;
@use "./mixin" as *;

.mod {
  height: 75%;
  width: 80%;
  margin-top: 50px;
  background-color: $white;
  overflow: auto;
  .modal-box {
    width: 100%;
    height: 40%;
    display: flex;
  }

  .nail-examples {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .image {
      width: 30em;
      height: 30em;
    }
  }
  @include respond-to("mobile") {
    height: 80%;
    width: 90%;
    .nail-examples {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .image {
        width: 23em;
        height: 30em;
      }
    }
  }
}
