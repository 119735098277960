@use "../container" as *;
@use "../variables/" as *;
@use "../components" as *;
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

.home {
  .container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;
    background-image: url("../../images/bghome.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: fcfaf9;
    .heading {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Courgette", cursive;
      h1 {
        margin-left: 2em;
        font-size: 2.5em;
      }
    }

    .home-img {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 0 0 1em 1em;
        width: 100%;
      }
    }
  }
  @include respond-to("tablet") {
    .container {
      height: 100vh;
      background-size: cover;
      .heading {
        width: 100%;
        display: inline-flex;
        position: absolute;
        margin-top: 55em;
        margin-right: 2em;
        font-size: 0.5em;
      }

      .home-img {
        width: 100%;
        position: absolute;
        img {
          width: 80%;
        }
      }
    }
  }
  @include respond-to("mobile") {
    .container {
      height: 100vh;
      background-size: cover;
      .heading {
        width: 100%;
        display: inline-flex;
        position: absolute;
        margin-top: 35em;
        margin-right: 2em;
        font-size: 0.4em;
      }

      .home-img {
        width: 100%;
        position: absolute;
        img {
          width: 80%;
        }
      }
    }
  }
  @include respond-to("laptop") {
    .container {
      .heading {
        font-size: 0.7em;
      }
    }
  }
}
