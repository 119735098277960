@use "../variables" as *;
@use "../container" as *;
@use "./mixin" as *;

.nav {
  position: relative;
  .nav-container {
    position: fixed;
    height: 13%;
    width: 100%;
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fcfaf9;
    .logo-img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3em;
      height: 100%;
      .logo {
        width: 40%;
        height: 80%;
      }
      .name {
        height: 70%;
      }
    }
    .hamburger-react {
      display: none;
    }
    .home {
      width: 30%;
      color: $black;
      margin-left: 1em;
    }
    .nav-links {
      margin-left: 15em;

      .link {
        color: $black;
        margin: 1.5em;
        text-decoration: none;
        &.active {
          text-decoration: underline 2px $main;
          text-underline-offset: 0.5em;
          transition: 300ms ease;
        }
        &:hover {
          text-decoration: underline 2px $main;
          text-underline-offset: 0.5em;
          transition: 300ms ease;
        }
      }
    }

    @include respond-to("tablet") {
      display: flex;
      height: 10%;
      width: 100%;
      justify-content: space-between;
      padding-left: 2em;
      h2 {
        font-size: 1em;
      }
      .logo-img {
        margin: 0;
        .logo {
          height: 75%;
          width: 100%;
          margin: 0;
        }
        .name {
          display: none;
        }
      }
      .hamburger-react {
        display: flex;
        right: 10%;
      }
      .nav-links {
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: space-evenly;
        align-items: center;
        top: 13%;
        border-radius: 0.5em;
        background-color: $main;
        right: 0%;
        opacity: 1;
        transition: opacity 400ms ease;
        .link {
          margin: 0em;
          color: $white;
          font-size: 1.5em;
          &.active {
            background-color: transparent;
            text-decoration: underline 2px $white;
            text-underline-offset: 0.5em;
            transition: 300ms ease;
            cursor: pointer;
          }
        }
        &.closed {
          .hamburger {
            transform: none;
          }
          opacity: 0;
          display: none;
        }
      }
    }
    @include respond-to("mobile") {
      display: flex;
      width: 100%;
      height: 10%;
      justify-content: space-between;
      padding-left: 2em;
      h2 {
        font-size: 1em;
      }
      .logo-img {
        height: 100%;
        width: 5em;
        margin: 0;
        .logo {
          height: 4em;
          width: 4em;
        }
        .name {
          display: none;
        }
      }
      .hamburger-react {
        display: flex;
        right: 10%;
      }
      .nav-links {
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: space-evenly;
        align-items: center;
        top: 10%;
        border-radius: 0.5em;
        background-color: $main;
        right: 0%;
        opacity: 1;
        transition: opacity 400ms ease;
        .link {
          margin: 0em;
          color: $white;
          font-size: 1.5em;
          &.active {
            background-color: transparent;
            text-decoration: underline 2px $white;
            text-underline-offset: 0.5em;
            transition: 300ms ease;
            cursor: pointer;
          }
        }
        &.closed {
          .hamburger {
            transform: none;
          }
          opacity: 0;
          display: none;
        }
      }
    }
  }
}
